import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { AppConstants } from '../../../../constants/app-constants.constants';
import { AppService } from '../../../../app.service';
import { CommunicationService } from '../../../../services/communication';
import { DIALOG_STANDARD_BACKGROUND_INFO_PROPERTIES } from '../../../../components/dialog/dialog-standard-background-info/dialog-standard-background-info.properties';
import { DialogStandardBackgroundInfoComponent } from '../../../../components/dialog/dialog-standard-background-info/dialog-standard-background-info.component';
import { DialogStandardFocus } from '../../../../enums/dialog-stantdard-focus';
import { DialogStandardFocusComponent } from '../../../../components/dialog/dialog-standard-focus/dialog-standard-focus.component';
import { DialogTransferReasonData } from '../../../../interfaces/transfer-reasons-dialog';
import { GenericRegexp } from '../../../../regexp/generic.regexp';
import { INPUT_MIN_MAX } from '../../../../constants/input-min-max.constants';
import { LanguageChangeEventService } from '../../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../../constants/language.constants';
import { LanguageTranslateService } from '../../../../services/translate/language-translate.service';
import { SearchMatSelectResults } from '../../../../interfaces/search-mat-select';
import { ToastrAlertsService } from '../../../../services/utils/toastr-alerts.service';
import { TRANSFER_REASONS_CREATE_CONST } from './transfer-reasons-create.properties';
import { TransferReasons } from '../../../../interfaces/transferReasons';
import { UtilsService } from '../../../../services/utils.service';

@Component({
  selector: 'app-transfer-reasons-create',
  styleUrls: ['./transfer-reasons-create.component.scss', '../../../../app.component.scss'],
  templateUrl: './transfer-reasons-create.component.html'
})
export class TransferReasonsCatalogCreateComponent implements OnInit {
  public hasChanges: boolean;
  public hasDeliveryTypeInReasonsList: boolean;
  public hasIdentifierInReasonsList: boolean;
  public isCreateButtonDisable: boolean;
  public isEdit: boolean;
  public isEditButtonEnabled: boolean;
  public isReadOnly: boolean;
  public languageLabels: any;
  public languageSuscription: Subscription;
  public selectedServices: Array<SearchMatSelectResults>;
  public selectedTransferReasons: string;
  public transferReasonsCreateLabelsTranslated: any;
  public transferReasonsForm: UntypedFormGroup;
  public transferReasonsToEdit: TransferReasons;
  public transferReasonsToView: Array<TransferReasons>;

  /**
   * @description Initializes a new instance of the TransferReasonsCreateComponent class.
   * @param {MatDialogRef<DialogStandardFocusComponent>} dialogRef - Reference to the dialog opened.
   * @param {AppService} appService - Service for application-wide operations.
   * @param {CommunicationService} communicationService - Service for handling communication between components.
   * @param {MatDialog} dialog - Service to open Material Design modal dialogs.
   * @param {UntypedFormBuilder} formBuilder - Service to create form controls.
   * @param {LanguageChangeEventService} languageChangeEventService - Service to handle language change events.
   * @param {LanguageTranslateService} languageTranslateService - Service to handle language translations.
   * @param {ToastrAlertsService} toastService - Service to show toast notifications.
   * @param {UtilsService} utils - Utility service for common operations.
   * @param {DialogTransferReasonData} data - Data injected into the dialog.
   */
  constructor(
    private appService: AppService,
    private communicationService: CommunicationService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private languageChangeEventService: LanguageChangeEventService,
    private languageTranslateService: LanguageTranslateService,
    private toastService: ToastrAlertsService,
    private utils: UtilsService,
    public dialogRef: MatDialogRef<DialogStandardFocusComponent>,

    @Inject(MAT_DIALOG_DATA) public data: DialogTransferReasonData
  ) {
    this.transferReasonsToEdit = this.data?.transferReasonsToEdit;
    this.selectedTransferReasons = this.data?.selectedTransferReasons;
    this.transferReasonsToView = this.data?.transferReasonsToView;
    this.isEdit = !!this.transferReasonsToEdit;
  }

  /**
   * @description Angular lifecycle hook for component init.
   */
  public async ngOnInit(): Promise<void> {
    this.initProperties();
    this.buildForm();
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    await this.getTransferReasonsViewLabels();
    this.setEditTransferReasons();
    this.listenFormChanges();
    this.listenSearchMatSelection();
  }

  /**
   * @description Executes when user clicks on cancel button.
   */
  public onCancel(): void {
    this.dialogRef.close();
  }

  /**
   * @description Executes when user clicks on create button.
   */
  public async onConfirm(): Promise<void> {
    const transferReasons = this.buildTransferReasonsBody();
    this.dialogRef.close(transferReasons);
  }

  /**
   * @description Fires when users uses keyboard.
   * @param {KeyboardEvent} event - Event from keyboard.
   */
  public onKeyPressCode(event: KeyboardEvent): void {
    const inputChar = event.key;

    if (!GenericRegexp.ONLY_NUMBERS.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
   * @description Check if all items are selected and set all select option.
   * @param {MatSelect} matSelect - Select from form.
   */
  public onSelectService(matSelect: MatSelect): void {
    const selectAllOption = matSelect.options.find((option: MatOption) => {
      return option?.value === AppConstants.EMPTY_STRING;
    });
    const isAllSelected = matSelect.options.filter((option: MatOption) => {
      return option?.value !== AppConstants.EMPTY_STRING;
    }).every((option: MatOption) => {
      return option.selected;
    });

    if (isAllSelected) {
      selectAllOption.select();
    } else {
      selectAllOption.deselect();
    }
  }

  /**
   * @description Opens a confirmation dialog for editing transfer reasons.
   */
  public showConfirmEditDialog(): void {
    const dialogRef =
      this.dialog.open(DialogStandardBackgroundInfoComponent, {
        data: {
          title: this.transferReasonsCreateLabelsTranslated.editTitle,
          backgroundIconColor: TRANSFER_REASONS_CREATE_CONST.iconColor,
          continue: this.transferReasonsCreateLabelsTranslated.continue,
          resume: this.transferReasonsCreateLabelsTranslated.dialogEditResume,
          question: this.transferReasonsCreateLabelsTranslated.dialogEditQuestion,
          cancel: this.transferReasonsCreateLabelsTranslated.cancel,
          icon: DIALOG_STANDARD_BACKGROUND_INFO_PROPERTIES.matInfoIcon,
        },
        width: TRANSFER_REASONS_CREATE_CONST.dialogWidth,
      });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === DialogStandardFocus.CONFIRM) {
        const transferReasonsToUpdate = this.buildTransferReasonsBody();
        this.dialogRef.close(transferReasonsToUpdate);
      }
    });
  }

  /**
   * @description Handles the change event for the delivery type selection.
   */
  public onDeliveryTypeChange(): void {
    const deliveryType = this.transferReasonsForm.controls.deliveryType.value;
    this.hasDeliveryTypeInReasonsList = this.transferReasonsToView.some((transferReasons: TransferReasons) => {
      return transferReasons.deliveryType === deliveryType;
    });
  }

  /**
   * @description Handles changes to the identifier field in the transfer reasons form.
   */
  public onIdentifierChange(): void {
    const identifier = this.transferReasonsForm.controls.identifier.value;
    this.hasIdentifierInReasonsList = this.transferReasonsToView.some((transferReasons: TransferReasons) => {
      return transferReasons.identifier === identifier;
    });
  }

  /**
   * @description Set and check if all items or some element if selected in the control.
   * @param {MatSelect} matSelect - Select from form.
   */
  public selectAllServices(matSelect: MatSelect): void {
    const selectAllOption = matSelect.options.find((option: MatOption) => {
      return option?.value === AppConstants.EMPTY_STRING;
    });

    if (selectAllOption.selected) {
      matSelect.options.forEach((item: MatOption) => {
        return item.select();
      });
    } else {
      matSelect.options.forEach((item: MatOption) => {
        return item.deselect();
      });
    }
  }

  /**
   * @description Builds form with necessary inputs and validations.
   */
  private buildForm(): void {
    this.transferReasonsForm = this.formBuilder.group({
      deliveryType: new UntypedFormControl(null, [
        Validators.required, Validators.minLength(INPUT_MIN_MAX.deliveryType.minLength),
        Validators.maxLength(INPUT_MIN_MAX.deliveryType.maxLength),
        Validators.minLength(INPUT_MIN_MAX.deliveryType.minLength)
      ]),
      identifier: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(INPUT_MIN_MAX.identifier.minLength),
        Validators.maxLength(INPUT_MIN_MAX.identifier.maxLength)
      ]),
      transferReasons: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(INPUT_MIN_MAX.transferReasons.minLength),
        Validators.maxLength(INPUT_MIN_MAX.transferReasons.maxLength)
      ])
    });
  }

  /**
   * @description Builds the body of a TransferReasons object using the form values and additional data.
   * @returns {TransferReasons} The constructed TransferReasons object.
   */
  private buildTransferReasonsBody(): TransferReasons {
    const transferReasons = {} as TransferReasons;
    const formValues = this.transferReasonsForm.value;
    transferReasons.deliveryType = formValues.deliveryType;
    transferReasons.identifier = formValues.identifier;
    transferReasons.reason = formValues.transferReasons;
    transferReasons.createdBy = this.appService.getUserOid();
    transferReasons.updatedBy = this.appService.getUserOid();
    transferReasons.shipperOId = this.data.shipperId;
    transferReasons._id = this.transferReasonsToEdit?._id;

    return transferReasons;
  }

  /**
   * @description Get Language labels from translate JSON files.
   */
  private async getLanguageTags(): Promise<void> {
    try {
      this.languageLabels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS);
    } catch (error) {
      this.toastService.errorAlert(this.languageLabels.errorGettingLabels);
    }
  }

  /**
   * @description Get labels for supplier component.
   */
  private async getTransferReasonsViewLabels(): Promise<void> {
    try {
      this.transferReasonsCreateLabelsTranslated =
        await this.languageTranslateService.getLanguageLabels(LanguageConstants.TRANSFER_REASONS_CREATE_LABELS);
    } catch (error) {
      this.toastService.errorAlert(this.languageLabels.errorGettingLabels);
    }
  }

  /**
   * @description Sets initial value for necessary properties.
   */
  private initProperties(): void {
    this.selectedServices = [];
    this.isReadOnly = false;
    this.hasChanges = false;
    this.isEditButtonEnabled = false;
    this.isCreateButtonDisable = true;
  }

  /**
   * @description Verify if the fields of form has change by user.
   */
  private listenFormChanges(): void {
    const initialValues = this.transferReasonsForm.value;
    this.transferReasonsForm.valueChanges.subscribe((values: any) => {
      this.hasChanges = this.utils.hasChangesFormSimple(initialValues, values);
      this.isEditButtonEnabled = this.hasChanges && this.transferReasonsForm.valid;
    });
  }

  /**
   * @description Listen select search fields.
   */
  private listenSearchMatSelection(): void {
    this.communicationService.searchMatSelectionSubscribe().subscribe((selection: Array<SearchMatSelectResults>) => {
      this.selectedServices = selection[0]._id ? selection : [];
      this.transferReasonsForm.controls.services.setValue(this.selectedServices);
      this.validateDisableCreateButton();
    });
  }

  /**
   * @description Sets the form controls with the values from the transferReasonsToEdit object.
   * @returns {void}
   */
  private setEditTransferReasons(): void {
    if (!this.transferReasonsToEdit) {
      return;
    }

    this.transferReasonsForm.controls.deliveryType.setValue(this.transferReasonsToEdit.deliveryType);
    this.transferReasonsForm.controls.identifier.setValue(this.transferReasonsToEdit.identifier);
    this.transferReasonsForm.controls.transferReasons.setValue(this.transferReasonsToEdit.reason);
    this.isReadOnly = true;
  }

  /**
   * @description React to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey - Optional language key string, default is spanish 'es'.
   */
  private setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description React to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   */
  private subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this.languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getTransferReasonsViewLabels();
      }, () => {
        this.toastService.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Validates if can enable create button.
   */
  private validateDisableCreateButton(): void {
    if (this.transferReasonsForm.valid && this.selectedServices.length) {
      this.isCreateButtonDisable = false;
    }
  }
}
